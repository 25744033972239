import { useGlobalStateStore } from "@/store/GlobalState"
import { isDevEnv } from "@/utils/enviroment"

class HardCodedUtil {
	private readonly adminUsersMap = {
		ANA_CAROLINE: 2,
		GUSTAVO_TEIXEIRA: 4,
		TONIM_LETALK: 5,
		BRUNO: 8,
		FINANCEIRO: 11,
		GABI: 12,
		GUSTAVO_BASTOS: 17,
		JOHN: 20,
		LAURA: 23,
		LETICIA: 24,
		PEDRO: 30,
		RAYAN: 31,
		RENATO: 33,
		SAMUEL: 36,
		VITORIA: 42,
		ANA: 43,
		LARA: 46,
		MARIANA_RODRIGUES: 47,
		DANDARA: 49,
		DAVID: 50,
		BRIAN: 51,
		LHUANA: 52,
		RENATO_CALABREZI: 53,
		SAMUEL_AMPARO: 54,
		JESSICA_MARIANO: 59
	}

	private get adminId () {
		const useGlobalState = useGlobalStateStore()

		return useGlobalState.userData.id as number
	}

	canDeleteAccountAndInstanceOnCascade (): boolean {
		return [
			this.adminUsersMap.GUSTAVO_TEIXEIRA,
			this.adminUsersMap.JOHN,
			this.adminUsersMap.RAYAN,
			this.adminUsersMap.LETICIA
		].includes(this.adminId) || isDevEnv
	}

	canClearInboxAttendances (): boolean {
		return [
			this.adminUsersMap.JOHN,
			this.adminUsersMap.SAMUEL,
			this.adminUsersMap.ANA
		].includes(this.adminId)
	}

	canChangeInstanceOwner (): boolean {
		return [
			this.adminUsersMap.GUSTAVO_TEIXEIRA,
			this.adminUsersMap.JOHN,
			this.adminUsersMap.RAYAN
		].includes(this.adminId) || isDevEnv
	}

	canBlockAccountSubscription (): boolean {
		return [
			this.adminUsersMap.GUSTAVO_TEIXEIRA,
			this.adminUsersMap.RAYAN,
			this.adminUsersMap.BRUNO,
			this.adminUsersMap.MARIANA_RODRIGUES,
			this.adminUsersMap.FINANCEIRO,
			this.adminUsersMap.DANDARA,
			this.adminUsersMap.SAMUEL_AMPARO
		].includes(this.adminId) || isDevEnv
	}

	canChangeModuleStatus (adminUserId: number): boolean {
		return [
			this.adminUsersMap.TONIM_LETALK,
			this.adminUsersMap.ANA,
			this.adminUsersMap.GABI,
			this.adminUsersMap.SAMUEL,
			this.adminUsersMap.ANA_CAROLINE,
			this.adminUsersMap.LARA,
			this.adminUsersMap.GUSTAVO_BASTOS,
			this.adminUsersMap.LAURA,
			this.adminUsersMap.LETICIA,
			this.adminUsersMap.MARIANA_RODRIGUES,
			this.adminUsersMap.PEDRO,
			this.adminUsersMap.RENATO,
			this.adminUsersMap.VITORIA,
			this.adminUsersMap.DAVID,
			this.adminUsersMap.LHUANA,
			this.adminUsersMap.BRIAN,
			this.adminUsersMap.RENATO_CALABREZI,
			this.adminUsersMap.JESSICA_MARIANO
		].includes(adminUserId)
	}

	canChangeUserMfa (): boolean {
		return [
			this.adminUsersMap.JOHN,
			this.adminUsersMap.RAYAN,
			this.adminUsersMap.ANA_CAROLINE,
			this.adminUsersMap.GUSTAVO_TEIXEIRA,
			this.adminUsersMap.RENATO,
			this.adminUsersMap.BRIAN,
			this.adminUsersMap.RENATO_CALABREZI,
			this.adminUsersMap.LETICIA,
			this.adminUsersMap.VITORIA,
			this.adminUsersMap.ANA_CAROLINE,
			this.adminUsersMap.JESSICA_MARIANO
		].includes(this.adminId)
	}
}

export default new HardCodedUtil()
