import { Divider, Loading, PopConfirm, Portlet, Notification, ActionDialog, CustomTextField } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { AccountAdminApi } from "@/services/Api"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { formatBrazilianCurrency } from "@/utils/mask"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import {
	Button,
	Grid, IconButton, InputAdornment,
	Paper, Table, TableBody,
	TableCell, TableContainer,
	TableHead,
	TableRow,
	TextField, Typography
} from "@material-ui/core"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import React, { useState } from "react"
import { GetPlanDataResponse, PlanColumns, PlanData } from "@/pages/Account/protocols/PlanProtocol"
import useStyles from "@/pages/Account/PlansAndAddons/Plans/styles"
import useCustomStyles from "@/styles/custom"
import CreatePlanFormDialog from "@/pages/Account/PlansAndAddons/Plans/CreatePlanFormDialog"
import { Link } from "react-router-dom"
import UpdateLimitsFormDialog from "@/pages/Account/PlansAndAddons/Plans/UpdateLimitsFormDialog/index"

const TableColumns: PlanColumns[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "display_name",
		label: "Nome do Plano"
	},
	{
		name: "created_at",
		label: "Data de Criação",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "price_in_cents",
		label: "Preço"
	},
	{
		name: "limits",
		label: "Limites"
	}
]

const Plans = () => {
	const [plans, setPlans] = useState<GetPlanDataResponse>(
		{} as GetPlanDataResponse
	)
	const [searchInputValue, setSearchInputValue] = useState<string | null>("")
	const [loading, setLoading] = useState<boolean>(true)
	const [showEditPlanDialog, setShowEditPlanDialog] = useState<boolean>(false)
	const [actualEditPlan, setActualEditPlan] = useState<PlanData>({} as PlanData)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)
	const [showCreatePlanDialog, setShowCreatePlanDialog] = useState<boolean>(false)
	const [showUpdatePlanLimitsDialog, setShowUpdatePlanLimitsDialog] = useState<boolean>(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const { validation, triggerValidation, clearValidation, parseError } = useValidation()

	const getPlans = async (searchValue?: string | null) => {
		setLoading(true)
		try {
			const { data } = await AccountAdminApi
				.get("/plans", {
					params: {
						searchValue
					}
				})

			setPlans(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const handleSearchChange = async (search: string) => {
		const searchValue: string = search

		setSearchInputValue(searchValue)

		await getPlans(searchValue)
	}

	const handleEditPlan = (plan: PlanData) => {
		setActualEditPlan(plan)
		clearValidation("app_id")
		clearValidation("code")
		clearValidation("display_name")
		clearValidation("price_in_cents")
		setShowEditPlanDialog(true)
	}

	const handleInputPlanChange = (name: string, value: string) => {
		clearValidation(name)

		const isInvalidNumber = !+value
		const isStringCharacter = value !== "0"

		const isPossiblyStringValue = isInvalidNumber && isStringCharacter

		if (isPossiblyStringValue) {
			setActualEditPlan({ ...actualEditPlan, [name]: value })
		} else {
			setActualEditPlan({ ...actualEditPlan, [name]: +value })
		}
	}

	const handleFinishEditPlan = async (planData: PlanData) => {
		setActionDialogLoading(true)
		try {
			await AccountAdminApi.put(`/plans/${planData.id}`, planData)

			setShowEditPlanDialog(false)
			await getPlans()
			Notification.success({ message: "Os dados foram salvos." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error as ErrorType)
		}

		setActionDialogLoading(false)
	}

	const deleteOnePlan = async (planId: number) => {
		try {
			await AccountAdminApi.delete(`/plans/${planId}`)

			await getPlans()
			Notification.success({ message: "Plano apagado com sucesso." })
		} catch (error) {
			triggerValidation(error as ErrorType)

			const translatedError = parseError(error as ErrorType)
			if (translatedError.errorName === "planIsLinkedToActiveSubscriptions") {
				Notification.warning({ message: "Não é possível excluir plano pois já existem assinaturas vinculadas a ele." })
			}
		}
	}

	const handleDeletePlan = async (planId: number) => {
		const props = {
			description: "Deseja deletar este plano?",
			confirmButtonText: "EXCLUIR",
			onConfirm: () => deleteOnePlan(planId)
		}
		PopConfirm.open(props)
	}

	useDidMount(() => {
		getPlans()
	})

	return <Grid>
		<Grid item xs={12}>
			<Grid item>
				<Button
					variant="contained"
					className={customClasses.buttonAction}
					onClick={() => {
						setShowCreatePlanDialog(true)
					}}
				>
					NOVO PLANO
				</Button>
			</Grid>

			<Divider size={1} orientation="horizontal" />

			<Portlet
				elevation={1}
			>
				<Divider size={3} orientation="horizontal" />

				<Grid container>
					<Grid container justify="flex-end">
						<Grid item xs={12} md={5}>
							<TextField
								value={searchInputValue}
								onChange={({ target }) =>
									handleSearchChange(target.value)
								}

								placeholder="Pesquisar"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: searchInputValue && (
										<IconButton
											size="small"
											onClick={() => handleSearchChange("")}
										>
											<CloseIcon
												fontSize="small"
											/>
										</IconButton>
									)
								}}
								fullWidth
								size="small"
							/>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12}>
						<Loading
							customLoadingElement={
								<CardSkeleton
									cardHeight={200}
								/>}
							loading={loading}>
							{
								plans.count > 0
									? <Paper className={classes.paper}>
										<TableContainer>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{TableColumns
															.map((column, index) => (
																<TableCell
																	key={index}
																>
																	{
																		column.label
																	}
																</TableCell>
															))}
														<TableCell align="center">
															Ações
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{
														plans.rows.map((plan, index) => {
															return (
																<TableRow
																	hover
																	tabIndex={-1}
																	key={index}
																	className={classes.tableRow}
																>
																	{TableColumns
																		.map((column, index) => {
																			let value = plan[column.name] as string

																			if (column?.formatDate) {
																				value = column.formatDate(value)
																			}
																			if (column?.name === "limits") {
																				value = JSON.stringify(value, null, 2)
																			}
																			if (column?.name === "price_in_cents") {
																				value = formatBrazilianCurrency(Number(value) / 100)
																			}

																			return (
																				<TableCell
																					key={index}
																					style={column?.style}
																					align={column?.align}
																				>
																					{
																						column?.name === "limits" ? <Link
																							to="#"
																							onClick={() => {
																								setActualEditPlan(plan)
																								setShowUpdatePlanLimitsDialog(true)
																							}}>
																							{value}
																						</Link> : value
																					}
																				</TableCell>
																			)
																		}
																		)
																	}

																	<TableCell
																		align="center"
																		style={{ display: "flex", justifyContent: "center" }}
																	>

																		<IconButton
																			key={index}
																			className={classes.editIcon}
																			onClick={(event) => {
																				event.preventDefault()
																				handleEditPlan(plan)
																			}}
																		>
																			<EditIcon fontSize="small" />
																		</IconButton>

																		<IconButton
																			key={plans.rows.length + index}
																			className={classes.deleteIcon}
																			onClick={(event) => {
																				event.preventDefault()
																				handleDeletePlan(plan.id)
																			}}
																		>
																			<DeleteIcon fontSize="small" />
																		</IconButton>

																	</TableCell>

																</TableRow>
															)
														})}
												</TableBody>
											</Table>
										</TableContainer>

									</Paper>
									: (
										<>
											<Divider size={3} orientation="horizontal" />
											<Typography align="center" variant="h2">
												Nenhum <b>plano</b> cadastrado
											</Typography>
										</>)
							}
						</Loading>

						<ActionDialog
							title="Alterar dados do plano"
							onSave={() => handleFinishEditPlan(actualEditPlan)}
							saveText="Salvar"
							onClose={() => setShowEditPlanDialog(false)}
							loading={actionDialogLoading}
							openDialog={showEditPlanDialog}
							fullWidth
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditPlan.app_id}
										onChange={
											({ target }) => handleInputPlanChange("app_id", target.value)
										}
										variant="outlined"
										placeholder="APP ID"
										fullWidth
										helperText={validation.app_id}
										error={!!validation.app_id}
									/>
								</Grid>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditPlan.code}
										onChange={
											({ target }) => handleInputPlanChange("code", target.value)
										}
										variant="outlined"
										placeholder="Código"
										fullWidth
										helperText={validation.code}
										error={!!validation.code}
									/>
								</Grid>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditPlan.display_name}
										onChange={
											({ target }) => handleInputPlanChange("display_name", target.value)
										}
										variant="outlined"
										placeholder="Nome"
										fullWidth
										helperText={validation.display_name}
										error={!!validation.display_name}
									/>
								</Grid>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditPlan.price_in_cents}
										onChange={
											({ target }) => handleInputPlanChange("price_in_cents", target.value)
										}
										variant="outlined"
										placeholder="Preço(em centavos)"
										fullWidth
										helperText={validation.price_in_cents}
										error={!!validation.price_in_cents}
									/>
								</Grid>
								<Grid item xs={12}>

								</Grid>
							</Grid>
						</ActionDialog>

						<CreatePlanFormDialog
							showCreatePlanDialog={showCreatePlanDialog}
							setShowCreatePlanDialog={setShowCreatePlanDialog}
							getPlans={getPlans}
						/>

						<UpdateLimitsFormDialog
							planData={actualEditPlan}
							showDialog={showUpdatePlanLimitsDialog}
							setShowDialog={setShowUpdatePlanLimitsDialog}
							getPlans={getPlans}
						/>

					</Grid>
				</Grid>
			</Portlet>
		</Grid>
	</Grid>
}

export default Plans
