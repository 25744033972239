import React, { useState } from "react"

import {
	Grid,
	Typography,
	Paper,
	TableContainer,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	FormControl,
	Select,
	MenuItem,
	Button,
	CircularProgress,
	InputLabel,
	FormControlLabel,
	Checkbox
} from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"

import { AccountAdminApi } from "@/services/Api"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import { ActionDialog, Divider, Loading, Portlet, Notification } from "@/components"

import { GetSubscriptionData, SubscriptionsWhereData } from "@/pages/Account/protocols/SubscriptionProtocol"

import CardSkeleton from "@/skeletons/CardSkeleton"

import useStyles from "@/pages/Account/Account/Details/styles"
import useAccountClasses from "@/pages/Account/Account/styles"

import SubscriptionRow, { SubscriptionsTableColumns } from "@/pages/Account/Account/Details/components/SubscriptionRow"
import useCustomStyles from "@/styles/custom"
import { PlanData } from "@/pages/Account/protocols/PlanProtocol"
import colors from "@/styles/colors"
import HardCodedUtil from "@/shared/utils/hardcoded"

type NewSubscription = {
	plan_id: number
	expiration_date: Date
	is_trial: boolean
}

type SubscriptionTableProps = {
	accountId: number
	appInstanceId: number
}

const SubscriptionTable: React.FC<SubscriptionTableProps> = (props) => {
	const {
		accountId,
		appInstanceId
	} = props
	const [subscriptionsData, setSubscriptionsData] = useState<GetSubscriptionData>({} as GetSubscriptionData)
	const [getSubscriptionsDataLoading, setGetSubscriptionsDataLoading] = useState<boolean>(true)

	const [subscriptionWhereData, setSubscriptionWhereData] = useState<SubscriptionsWhereData>({
		page: 0,
		rowsPerPage: 50
	})

	const [creatingSubscription, setCreatingSubscription] = useState<boolean>(false)
	const [blockingAccountSubscription, setBlockingAccountSubscription] = useState<boolean>(false)
	const [openCreateSubscriptionDialog, setOpenCreateSubscriptionDialog] = useState<boolean>(false)
	const [openBlockSubscriptionDialog, setOpenBlockSubscriptionDialog] = useState<boolean>(false)
	const [comunicateSubscriptionBlocking, setComunicateSubscriptionBlocking] = useState<boolean>(true)
	const [newSubscription, setNewSubscription] = useState<Partial<NewSubscription>>({ expiration_date: new Date(), is_trial: false })
	const [plans, setPlans] = useState<PlanData[]>([])

	const {
		triggerValidation
	} = useValidation()
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const accountClasses = useAccountClasses()

	const getSubscriptions = async (newWhereData?: Partial<SubscriptionsWhereData>) => {
		setGetSubscriptionsDataLoading(true)
		try {
			const { data } = await AccountAdminApi.get(`/subscriptions/${accountId}/${appInstanceId}`, {
				params: {
					...subscriptionWhereData,
					...newWhereData
				}
			})

			setSubscriptionsData(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setGetSubscriptionsDataLoading(false)
	}

	const handleSubscriptionWhereDataChange = (newData: Partial<SubscriptionsWhereData>) => {
		setSubscriptionWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handleSubscriptionPageChange = async (event: unknown, page: number) => {
		handleSubscriptionWhereDataChange({
			page
		})

		await getSubscriptions({ page })
	}

	const handleSubscriptionChangeRowsPerPage = async (rowsPerPage: number) => {
		handleSubscriptionWhereDataChange({
			rowsPerPage
		})
		await getSubscriptions({ rowsPerPage })
	}

	const handleOpenCreateSubscriptionDialog = () => {
		setOpenCreateSubscriptionDialog(true)
	}

	const handleCloseCreateSubscriptionDialog = () => {
		setOpenCreateSubscriptionDialog(false)
	}

	const handleOpenBlockSubscriptionDialog = () => {
		setOpenBlockSubscriptionDialog(true)
	}

	const handleCloseBlockSubscriptionDialog = () => {
		setOpenBlockSubscriptionDialog(false)
	}

	const handleBlockAccountSubscription = async () => {
		setBlockingAccountSubscription(true)

		try {
			await AccountAdminApi.post("/subscriptions/block", {
				account_id: accountId,
				app_instance_id: appInstanceId,
				comunicateSubscriptionBlocking
			})

			Notification.success({ message: "Assinatura bloqueada com sucesso!" })
			handleCloseBlockSubscriptionDialog()
			await getSubscriptions()
		} catch (error) {
			Notification.error({ message: "Erro ao bloquear assinatura!" })
		}

		setBlockingAccountSubscription(false)
	}

	const handleUnblockAccountSubscription = async () => {
		setBlockingAccountSubscription(true)

		try {
			await AccountAdminApi.post("/subscriptions/unblock", {
				account_id: accountId,
				app_instance_id: appInstanceId
			})

			Notification.success({ message: "Assinatura desbloqueada com sucesso!" })

			await getSubscriptions()
		} catch (error) {
			Notification.error({ message: "Erro ao desbloquear assinatura!" })
		}

		setBlockingAccountSubscription(false)
	}

	const handleCreateSubscription = async () => {
		setCreatingSubscription(true)

		if (!newSubscription.plan_id) {
			Notification.error({ message: "Escolha um plano!" })
			return
		}

		try {
			await AccountAdminApi.post("/subscriptions", {
				account_id: accountId,
				app_instance_id: appInstanceId,
				...newSubscription
			})

			Notification.success({ message: `Assinatura criada com sucesso para instância da conta ${accountId}!` })
			handleCloseCreateSubscriptionDialog()
			await getSubscriptions()
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: `Erro ao tentar criar assinatura para instância da conta ${accountId}!` })
		}

		setCreatingSubscription(false)
	}

	const getAndUpdatePlans = async () => {
		try {
			const response = await AccountAdminApi.get("/plans")
			const inboxPlans = response.data.rows.filter((plan: PlanData) => plan.app_id === 2)

			setPlans(inboxPlans)
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Erro ao buscar os planos!" })
		}
	}

	useDidMount(() => {
		getSubscriptions()
		getAndUpdatePlans()
	})

	return (
		<Grid>
			<Grid item xs={12}>
				<Typography
					color="textPrimary"
					className={classes.title}
				>
                    Assinaturas
				</Typography>

				<Divider size={1} orientation="horizontal" />

				<Grid
					container
				>
					<Button
						onClick={handleOpenCreateSubscriptionDialog}
						variant="contained"
						className={customClasses.buttonAction}
						endIcon={creatingSubscription && <CircularProgress size={20} />}
						disabled={creatingSubscription}
					>
						Nova Assinatura
					</Button>

					<Divider size={1} orientation="vertical" />
					{
						HardCodedUtil.canBlockAccountSubscription() && (
							<>
								<Button
									style={{
										backgroundColor: colors.palette.error
									}}
									onClick={() => {
										handleOpenBlockSubscriptionDialog()
									}}
									variant="contained"
									className={customClasses.buttonAction}
									endIcon={blockingAccountSubscription && <CircularProgress size={20} />}
									disabled={blockingAccountSubscription}
								>
									Bloquear assinatura
								</Button>

								<Divider size={1} orientation="vertical" />

								<Button
									style={{
										backgroundColor: colors.palette.link
									}}
									onClick={() => {
										handleUnblockAccountSubscription()
									}}
									variant="contained"
									className={customClasses.buttonAction}
									endIcon={blockingAccountSubscription && <CircularProgress size={20} />}
									disabled={blockingAccountSubscription}
								>
									Desbloquear assinatura
								</Button>
							</>
						)
					}
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Portlet
					elevation={1}
				>
					<Loading
						customLoadingElement={
							<CardSkeleton
								cardHeight={400}
							/>}
						loading={getSubscriptionsDataLoading}
					>
						<>
							<Divider size={2} orientation="horizontal" />
							{
								subscriptionsData.count > 0
									? <Paper className={accountClasses.paper}>
										<TableContainer>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{/* Collapse button */}
														<TableCell />

														{SubscriptionsTableColumns
															.map((column, index) => (
																<TableCell
																	key={index}
																>
																	{column.label}
																</TableCell>
															))}
													</TableRow>
												</TableHead>
												<TableBody>
													{
														subscriptionsData.rows.map((subscription, index) => (
															<SubscriptionRow
																key={index}
																subscriptionData={subscription}
															/>
														))
													}
												</TableBody>
											</Table>
										</TableContainer>

										<TablePagination
											rowsPerPageOptions={[20, 50, 100, 200]}
											component="div"
											count={subscriptionsData?.count}
											rowsPerPage={subscriptionWhereData.rowsPerPage}
											page={subscriptionWhereData.page}
											onPageChange={handleSubscriptionPageChange}
											onRowsPerPageChange={({ target }) => handleSubscriptionChangeRowsPerPage(+target.value)}
											labelRowsPerPage={"Resultados por página:"}
											labelDisplayedRows={e =>
												`Página ${e.page + 1} de ${Math.ceil(e.count / subscriptionWhereData.rowsPerPage)} | Total de resultados: ${subscriptionsData?.count}`
											}
										/>
									</Paper>
									: (
										<>
											<Divider size={3} orientation="horizontal" />
											<Typography align="center" variant="h2">
                                                Nenhuma assinatura cadastrada, clique em <strong>Nova assinatura</strong> para criar a primeira!
											</Typography>
										</>)
							}
						</>
					</Loading>
				</Portlet>

			</Grid>

			<ActionDialog
				title={"Criar nova assinatura"}
				onSave={handleCreateSubscription}
				saveText="Criar"
				onClose={handleCloseCreateSubscriptionDialog}
				openDialog={openCreateSubscriptionDialog}
				fullWidth
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="plan">Plano</InputLabel>
							<Select
								label="Plano"
								labelId="plan"
								onChange={({ target }) => {
									setNewSubscription({
										...newSubscription,
										plan_id: target.value as number
									})
								}}
								value={newSubscription.plan_id}
							>
								{plans.map(plan => (
									<MenuItem value={plan.id} key={plan.id}>
										{plan.display_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
							<KeyboardDatePicker
								margin="normal"
								inputVariant="outlined"
								format="dd/MM/yyyy"
								animateYearScrolling
								views={["year", "month", "date"]}
								value={newSubscription.expiration_date}
								color="primary"
								className={customClasses.datePicker}
								InputProps={{
									className: customClasses.datePickerInput
								}}
								cancelLabel="Cancelar"
								invalidDateMessage="Data inválida"
								onChange={(date) => {
									setNewSubscription({
										...newSubscription,
										expiration_date: date as Date
									})
								}}
								label="Data de expiração"
							/>
						</MuiPickersUtilsProvider>
					</Grid>

					<Grid item xs={12}>
						<FormControlLabel
							label="Trial"
							control={
								<Checkbox
									checked={newSubscription.is_trial}
									onChange={({ target }) => {
										setNewSubscription({
											...newSubscription,
											is_trial: target.checked as boolean
										})
									}}
								/>
							}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />
				</Grid>
			</ActionDialog>

			<ActionDialog
				title={"Bloquear assinatura"}
				onSave={handleBlockAccountSubscription}
				saveText="Bloquear"
				onClose={handleCloseBlockSubscriptionDialog}
				openDialog={openBlockSubscriptionDialog}
				fullWidth
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControlLabel
							label="Comunicar cliente do bloqueio?"
							control={
								<Checkbox
									checked={comunicateSubscriptionBlocking}
									onChange={({ target }) => {
										setComunicateSubscriptionBlocking(target.checked)
									}}
								/>
							}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />
				</Grid>
			</ActionDialog>

		</Grid>
	)
}

export default SubscriptionTable
