import { ActionDialog, Notification, Divider } from "@/components"
import { InboxChannelProps } from "@/pages/Inbox/protocols/ChannelProtocol"
import { ProcedureRequest, ChannelChatStatus, SequelizeOperator } from "@/pages/Inbox/protocols/InstanceProtocol"
import { GetUsersInInstanceResponseProps } from "@/pages/Inbox/protocols/UserInInstanceProtocol"
import { InboxAdminApi } from "@/services/Api"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import DateFnsUtils from "@date-io/date-fns"
import { Grid, Typography, Select, MenuItem, Button, InputLabel, FormControl } from "@material-ui/core"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import React, { useState } from "react"
import ptBR from "date-fns/locale/pt-BR"
type ClearQueueType = {
	integrationId?: string
	chatStatus: ChannelChatStatus
	operator: SequelizeOperator
	before?: Date
	after?: Date
	procedure: ProcedureRequest
	userId?: number
}

type ManageInstanceChatsProps = {
	inboxChannel: InboxChannelProps
	usersInInstance: GetUsersInInstanceResponseProps
}

const channelChatStatus: ChannelChatStatus[] = ["queue", "on-going"]
const sequelizeOperatorValues: SequelizeOperator[] = [
	SequelizeOperator.Between,
	SequelizeOperator.BeforeOrEqual,
	SequelizeOperator.AfterOrEqual
]
const procedure: ProcedureRequest[] = ["finish"]

const ManageInstanceChats: React.FC<ManageInstanceChatsProps> = (props) => {
	const { inboxChannel, usersInInstance } = props

	const stateInializer: ClearQueueType = {
		after: new Date(),
		before: new Date(),
		chatStatus: "queue",
		operator: SequelizeOperator.BeforeOrEqual,
		procedure: "finish",
		integrationId: inboxChannel?.rows[0]?.integrationId
	}
	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const [handlingClearChats, setHandlingClearChats] = useState<boolean>(false)
	const [queueDataParams, setClearQueueDataParams] = useState<ClearQueueType>(stateInializer)

	const clearQueueDataParams = (value: string | Date, field: keyof ClearQueueType) => {
		let updatedParams: ClearQueueType = { ...queueDataParams }
		const isBetweenOperator = queueDataParams.operator === "between"
		const isCurrentAssignmentReferenceToQueue = value === "queue"
		const shouldOnlyUseBeforeDate = field === "before" && !isBetweenOperator
		const shouldOnlyUseAfterDate = field === "after" && !isBetweenOperator

		if (isCurrentAssignmentReferenceToQueue) {
			updatedParams.userId = undefined
		} else if (shouldOnlyUseBeforeDate) {
			updatedParams.after = undefined
		} else if (shouldOnlyUseAfterDate) {
			updatedParams.before = undefined
		}

		updatedParams = {
			...updatedParams,
			[field]: value
		}

		setClearQueueDataParams(lastState => ({
			...lastState,
			...updatedParams
		}))
	}

	const handleClearQueueChats = async () => {
		setHandlingClearChats(true)

		try {
			const data = await InboxAdminApi.post("channel/chats/attendances/clear", { queueDataParams })

			if (data.status === 200) {
				Notification.success({ message: "Limpeza realizada" })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro ao limpar a aba" })
		}
		setHandlingClearChats(false)
	}
	const chatStatusToString: Record<ChannelChatStatus, string> = {
		"on-going": "Em atendimento",
		queue: "Novos"
	}

	const sequelizeOperatorToString: Record<SequelizeOperator, string> = {
		[SequelizeOperator.Between]: "Entre",
		[SequelizeOperator.BeforeOrEqual]: "Anterior",
		[SequelizeOperator.AfterOrEqual]: "Após"
	}

	const sequelizeOperatorToDateRange: Record<SequelizeOperator, { field: string; label: string }[]> = {
		[SequelizeOperator.Between]: [
			{ field: "before", label: sequelizeOperatorToString[SequelizeOperator.BeforeOrEqual] },
			{ field: "after", label: sequelizeOperatorToString[SequelizeOperator.AfterOrEqual] }
		],
		[SequelizeOperator.BeforeOrEqual]: [
			{ field: "before", label: sequelizeOperatorToString[SequelizeOperator.BeforeOrEqual] }
		],
		[SequelizeOperator.AfterOrEqual]: [
			{ field: "after", label: sequelizeOperatorToString[SequelizeOperator.AfterOrEqual] }
		]
	}

	const selectedDateRangeOperator = sequelizeOperatorToDateRange[queueDataParams.operator]

	const procedureRequestToString: Record<ProcedureRequest, string> = {
		finish: "Finalizar"
	}

	const turnDataInActionSummary = () => {
		const userName = usersInInstance?.data?.find((user) => user.id === queueDataParams.userId)?.name
		const { operator, before, after, procedure, chatStatus } = queueDataParams
		const selectedOperator = sequelizeOperatorToString[operator]?.toLowerCase() || ""

		const linkedWithAttendant = userName ? `, vinculados ao atendente ${userName}` : ""

		const isBetweenDateRangeOperator = operator === "between"
		const formattedBeforeDate = formatDateAndTimeBrazilianDate(before).dateAndTime
		const formattedAfterDate = formatDateAndTimeBrazilianDate(after).dateAndTime

		const dateRangeBySingleDate = `${selectedOperator} ${operator === "lte" ? formattedBeforeDate : formattedAfterDate}`
		const dateRangeByBetweenDate = `a partir de ${formattedBeforeDate} até ${formattedAfterDate}`

		const inDateRange = isBetweenDateRangeOperator ? dateRangeByBetweenDate : dateRangeBySingleDate

		const action = procedureRequestToString[procedure] || ""
		const attendanceChatStatus = chatStatusToString[chatStatus] || ""

		return `${action} atendimentos da aba ${attendanceChatStatus}${linkedWithAttendant} ${inDateRange}`
	}

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}
	const handleCloseDialog = () => {
		setOpenDialog(false)
	}
	return (
		<>
			<Button onClick={handleOpenDialog}>
				ADMINISTRAR CONTATOS DA INSTÂNCIA
			</Button>
			<ActionDialog
				title="ADMINISTRAR CONTATOS DA INSTÂNCIA"
				onSave={handleClearQueueChats}
				openDialog={openDialog}
				onClose={handleCloseDialog}
				saveText="Limpar"
				loading={handlingClearChats}
			>
				<Grid item style={{ width: 550 }}>
					<Grid container spacing={2} direction="row">
						<Grid item>
							<FormControl fullWidth variant="outlined" size="small" required={true}>
								<InputLabel>
									Ação
								</InputLabel>
								<Select
									label="Ação"
									fullWidth
									value={queueDataParams.procedure}
									onChange={(event) => {
										clearQueueDataParams(event.target.value as string, "chatStatus")
									}}
								>
									{procedure?.map((status) => {
										return <MenuItem
											key={status}
											value={status}
										>
											{procedureRequestToString[status]}
										</MenuItem>
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl fullWidth variant="outlined" size="small" required={true}>
								<InputLabel>
									Aba
								</InputLabel>
								<Select
									variant="outlined"
									label="Aba"
									fullWidth
									defaultValue={queueDataParams.chatStatus}
									onChange={(event) => {
										clearQueueDataParams(event.target.value as string, "chatStatus")
									}}
								>
									{channelChatStatus?.map((status) => {
										return <MenuItem
											key={status}
											value={status}
										>
											{chatStatusToString[status]}
										</MenuItem>
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							{queueDataParams.chatStatus === "on-going" &&
								<FormControl fullWidth variant="outlined" size="small" required={true}>
									<InputLabel>
										Atendente
									</InputLabel>
									<Select
										variant="outlined"
										label="Atendente"
										style={{ width: "150px" }}
										value={queueDataParams?.userId}
										error={!queueDataParams?.userId}
										onChange={(event) => {
											clearQueueDataParams(event.target.value as string, "userId")
										}}
									>
										{usersInInstance.data?.map((user) => {
											return <MenuItem
												key={user.id}
												value={user.id}
											>
												{user.name}
											</MenuItem>
										})}
									</Select>
								</FormControl>
							}
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid container spacing={2}>
					<Grid item>
						<FormControl fullWidth variant="outlined" size="small" required={true}>
							<InputLabel>
								Período
							</InputLabel>
							<Select
								variant="outlined"
								fullWidth
								label="Período"
								value={queueDataParams.operator}
								onChange={(event) => {
									clearQueueDataParams(event.target.value as string, "operator")
								}}
							>
								{sequelizeOperatorValues?.map((operator) => {
									return <MenuItem
										key={operator}
										value={operator}
									>
										{sequelizeOperatorToString[operator]}
									</MenuItem>
								})}
							</Select>
						</FormControl>
					</Grid>
					{selectedDateRangeOperator?.map((dateRange) => {
						return (
							<Grid item>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
									<DateTimePicker
										ampm={false}
										size="small"
										format="dd/MM/yyyy HH:mm"
										animateYearScrolling
										label={dateRange.label}
										views={["year", "month", "date", "hours", "minutes"]}
										variant="inline"
										value={queueDataParams[dateRange.field as keyof ClearQueueType]}
										inputVariant="outlined"
										fullWidth
										onChange={(date) => clearQueueDataParams(date || new Date(), dateRange.field as keyof ClearQueueType)}
										autoOk
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						)
					})}
				</Grid>
				<Divider orientation="horizontal" size={2} />

				<Grid item>
					<Typography variant="subtitle2">
						{turnDataInActionSummary()}
					</Typography>
				</Grid>
			</ActionDialog >
		</>
	)
}

export default ManageInstanceChats
