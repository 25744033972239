import { Divider, Loading, PopConfirm, Portlet, Notification, ActionDialog, CustomTextField } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { AccountAdminApi } from "@/services/Api"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { formatBrazilianCurrency, formatIntNumber } from "@/utils/mask"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import {
	Button,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core"

import {
	Delete as DeleteIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import React, { useState } from "react"
import { AddonColumns, AddonData, AddonsByTypeResponse } from "@/pages/Account/protocols/AddonProtocol"
import useStyles from "@/pages/Account/PlansAndAddons/Plans/styles"
import useCustomStyles from "@/styles/custom"
import CreateAddonFormDialog from "@/pages/Account/PlansAndAddons/Addons/CreateAddonFormDialog"
import { Link } from "react-router-dom"
import UpdateLimitsFormDialog from "@/pages/Account/PlansAndAddons/Addons/UpdateLimitsFormDialog/index"

const TableColumns: AddonColumns[] = [
	{
		name: "display_name",
		label: "Nome do adicional"
	},
	{
		name: "price_in_cents",
		label: "Preço"
	},
	{
		name: "created_at",
		label: "Data de Criação",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "limits",
		label: "Limites"
	}
]

const Addons = () => {
	const [addonsByType, setAddonsByType] = useState<AddonsByTypeResponse[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [showEditAddonDialog, setShowEditAddonDialog] = useState<boolean>(false)
	const [actualEditAddon, setActualEditAddon] = useState<AddonData>({} as AddonData)
	const [creationAddonTypeId, setCreationAddonTypeId] = useState<number>()
	const [creationAddonTypeDisplayName, setCreationAddonTypeDisplayName] = useState<string>()
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)
	const [showCreateAddonDialog, setShowCreateAddonDialog] = useState<boolean>(false)
	const [showUpdateAddonLimitsDialog, setShowUpdateAddonLimitsDialog] = useState<boolean>(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const { validation, triggerValidation, clearValidation } = useValidation()

	const getAddons = async () => {
		setLoading(true)
		try {
			const { data } = await AccountAdminApi
				.get("/plan-addons-by-types")

			setAddonsByType(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const handleEditAddon = (addon: AddonData) => {
		setActualEditAddon(addon)
		clearValidation("display_name")
		clearValidation("price_in_cents")
		setShowEditAddonDialog(true)
	}

	const handleInputAddonChange = (name: string, value: string) => {
		clearValidation(name)

		const inputHandlerRules: Record<string, () => (string | number)> = {
			price_in_cents: function () {
				return formatIntNumber(value)
			},
			default: function () {
				return value
			}
		}
		const rule = inputHandlerRules[name] ? name : "default"
		const ruleHandleFunction = inputHandlerRules[rule]

		setActualEditAddon({ ...actualEditAddon, [name]: ruleHandleFunction() })
	}

	const handleFinishEditAddon = async (addonData: AddonData) => {
		setActionDialogLoading(true)
		try {
			await AccountAdminApi.put(`/plan-addons/${addonData.id}`, addonData)

			setShowEditAddonDialog(false)
			await getAddons()
			Notification.success({ message: "Os dados foram salvos." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error as ErrorType)
		}

		setActionDialogLoading(false)
	}

	// eslint-disable-next-line
	const deleteOneAddon = async (addonId: number) => {
		// try {
		// 	await AccountAdminApi.delete(`/plan-addons/${addonId}`)

		// 	await getAddons()
		// 	Notification.success({ message: "Adicional apagado com sucesso." })
		// } catch (error) {
		// 	triggerValidation(error)
		// }
	}

	const handleDeleteAddon = async (addonId: number) => {
		const props = {
			description: "Deseja deletar este adicional?",
			confirmButtonText: "EXCLUIR",
			onConfirm: () => deleteOneAddon(addonId)
		}
		PopConfirm.open(props)
	}

	const handleOpenCreateAddon = (addonTypeId: number, addonTypeDisplayName: string) => {
		setCreationAddonTypeId(addonTypeId)
		setCreationAddonTypeDisplayName(addonTypeDisplayName)
		setShowCreateAddonDialog(true)
	}

	useDidMount(() => {
		getAddons()
	})

	return <Grid>
		<Grid item xs={12}>
			{
				addonsByType.map(addonByType => {
					const { typeDisplayName, typeId, addons } = addonByType

					return (
						<>
							<Divider size={2} orientation="horizontal" />

							<Typography
								className={classes.title}
							>
								{typeDisplayName}
							</Typography>

							<Divider size={3} orientation="horizontal" />

							<Portlet
								elevation={1}
							>
								<Divider size={3} orientation="horizontal" />

								<Grid container>
									<Grid item xs={12}>
										<Loading
											customLoadingElement={
												<CardSkeleton
													cardHeight={200}
												/>}
											loading={loading}>
											{
												addons.length > 0
													? <Paper className={classes.paper}>
														<TableContainer>
															<Table stickyHeader aria-label="sticky table">
																<TableHead>
																	<TableRow>
																		{TableColumns
																			.map((column, index) => (
																				<TableCell
																					key={index}
																				>
																					{
																						column.label
																					}
																				</TableCell>
																			))}
																		<TableCell align="center">
																			Ações
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{
																		addons.map((addon, index) => {
																			return (
																				<TableRow
																					hover
																					tabIndex={-1}
																					key={index}
																					className={classes.tableRow}
																				>
																					{TableColumns
																						.map((column, index) => {
																							let value = addon[column.name] as string

																							if (column?.formatDate) {
																								value = column.formatDate(value)
																							}
																							if (column?.name === "limits") {
																								value = JSON.stringify(value, null, 2)
																							}
																							if (column?.name === "price_in_cents") {
																								value = Number(value) === 0 ? "Gratuito" : formatBrazilianCurrency(Number(value) / 100)
																							}

																							return (
																								<TableCell
																									key={index}
																									style={column?.style}
																									align={column?.align}
																								>
																									{
																										column?.name === "limits" ? <Link
																											to="#"
																											onClick={() => {
																												setActualEditAddon(addon)
																												setShowUpdateAddonLimitsDialog(true)
																											}}>
																											{value}
																										</Link> : value
																									}
																								</TableCell>
																							)
																						}
																						)
																					}

																					<TableCell
																						align="center"
																						style={{ display: "flex", justifyContent: "center" }}
																					>

																						<IconButton
																							key={index}
																							className={classes.editIcon}
																							onClick={(event) => {
																								event.preventDefault()
																								handleEditAddon(addon)
																							}}
																						>
																							<EditIcon fontSize="small" />
																						</IconButton>

																						<IconButton
																							key={addons.length + index}
																							className={classes.deleteIcon}
																							onClick={(event) => {
																								event.preventDefault()
																								handleDeleteAddon(addon.id)
																							}}
																						>
																							<DeleteIcon fontSize="small" />
																						</IconButton>

																					</TableCell>

																				</TableRow>
																			)
																		})}
																</TableBody>
															</Table>
														</TableContainer>

													</Paper>
													: (
														<>
															<Divider size={3} orientation="horizontal" />
															<Typography align="center" variant="h2">
																Nenhum <b>adicional</b> cadastrado
															</Typography>
														</>)
											}
										</Loading>

										<ActionDialog
											title="Alterar dados do adicional"
											onSave={() => handleFinishEditAddon(actualEditAddon)}
											saveText="Salvar"
											onClose={() => setShowEditAddonDialog(false)}
											loading={actionDialogLoading}
											openDialog={showEditAddonDialog}
											fullWidth
										>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<CustomTextField
														value={actualEditAddon.display_name}
														onChange={
															({ target }) => handleInputAddonChange("display_name", target.value)
														}
														variant="outlined"
														placeholder="Nome"
														fullWidth
														helperText={validation.display_name}
														error={!!validation.display_name}
													/>
												</Grid>
												<Grid item xs={12}>
													<CustomTextField
														value={actualEditAddon.price_in_cents}
														onChange={
															({ target }) => handleInputAddonChange("price_in_cents", target.value)
														}
														variant="outlined"
														placeholder="Preço(em centavos)"
														fullWidth
														helperText={validation.price_in_cents}
														error={!!validation.price_in_cents}
													/>
												</Grid>
												<Grid item xs={12}>

												</Grid>
											</Grid>
										</ActionDialog>
									</Grid>

									<Divider size={3} orientation="horizontal" />

									<Grid item>
										<Button
											variant="contained"
											className={customClasses.buttonAction}
											onClick={() => handleOpenCreateAddon(typeId, typeDisplayName)}
										>
											NOVO ADICIONAL
										</Button>
									</Grid>
								</Grid>
							</Portlet>

							<Divider size={4} orientation="horizontal" />
						</>
					)
				})
			}
		</Grid>

		<CreateAddonFormDialog
			addonTypeDisplayName={creationAddonTypeDisplayName}
			addonTypeId={creationAddonTypeId}
			showCreateAddonDialog={showCreateAddonDialog}
			setShowCreateAddonDialog={setShowCreateAddonDialog}
			getAddons={getAddons}
		/>

		<UpdateLimitsFormDialog
			addonData={actualEditAddon}
			showDialog={showUpdateAddonLimitsDialog}
			setShowDialog={setShowUpdateAddonLimitsDialog}
			getAddons={getAddons}
		/>
	</Grid>
}

export default Addons
