import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	paper: {
		width: "100%"
	},
	tableRow: {
		cursor: "auto",
		textDecoration: "none !important"
	},
	dateDeleteIcon: {
		padding: "5px"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	statusSelect: {
		minWidth: "100%"
	},
	launchIcon: {
		padding: "5px"
	},
	libName: {
		color: colors.grayScale[1]
	}
})

export default useStyles
