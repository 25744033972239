import React, { ReactNode } from "react"

import {
	Grid,
	IconButton,
	TableCell,
	TableCellProps,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core"
import { Link } from "react-router-dom"
import {
	ArrowForwardIos as ArrowIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"

import { getInboxChannelStatusComponent, getInboxChannelStatusPlatformName } from "@/utils/channelStatus"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import { formatCnpj } from "@/utils/mask"
import HardCodedUtil from "@/shared/utils/hardcoded"

import { Divider } from "@/components"

import { InstanceAdminProps } from "@/pages/Inbox/protocols/InstanceProtocol"
import { ChannelType, InboxChannelStatus } from "@/pages/Inbox/protocols/ChannelProtocol"

import useStyles from "@/pages/Inbox/Instances/styles"

type InstanceColumn = {
	name: "id"
	| "account_instance_id"
	| "created_at"
	| "owner_user_id"
	| "owner_user_name"
	| "owner_user_email"
	| "inbox_channel_status"
	| "cnpj"
	label: string
	formatDate?: (value: string) => string
	formatCnpj?: (value: string) => string
	formatStatus?: (input: {
		status: InboxChannelStatus
		inboxChannelType: ChannelType
	}) => ReactNode
	style?: React.CSSProperties
	align?: TableCellProps["align"]
}

export const TableColumns: InstanceColumn[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "created_at",
		label: "Criado em",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "cnpj",
		label: "CNPJ",
		formatCnpj: (value: string) => formatCnpj(value)
	},
	{
		name: "owner_user_name",
		label: "Nome do Dono"
	},
	{
		name: "owner_user_email",
		label: "Email do Dono",
		style: {
			wordBreak: "break-word"
		}
	},
	{
		name: "inbox_channel_status",
		label: "Status do Whatsapp",
		// eslint-disable-next-line react/display-name
		formatStatus: (input: {
			status: InboxChannelStatus,
			inboxChannelType: ChannelType
		}): ReactNode => {
			return getInboxChannelStatusComponent(input)
		}
	}
]

const InstanceListItem = (props: {
	instance: InstanceAdminProps
	onDelete: (instanceId: number) => Promise<void>
}) => {
	const {
		instance,
		onDelete
	} = props

	const classes = useStyles()

	return (
		<TableRow
			hover
			tabIndex={-1}
			className={classes.tableRow}
		>
			{
				TableColumns
					.map((column, index) => {
						let value = instance[column.name] as ReactNode

						if (column?.formatDate) {
							value = column.formatDate(value as string)
						}

						if (column?.formatCnpj) {
							value = column.formatCnpj(value as string || "")
						}

						if (column?.formatStatus) {
							value = column.formatStatus({
								status: value as InboxChannelStatus,
								inboxChannelType: instance.inbox_channel_type
							})
						}

						return (
							<TableCell
								key={index}
								style={column?.style}
								align={column?.align}
							>
								{column.name === "owner_user_name"
									? (
										<Link
											target="_blank"
											rel="noopener noreferrer"
											to={`/inbox/instance/${instance.id}`}
										>
											{value}
										</Link>
									) : column.name === "inbox_channel_status" ? (
										<Grid
											container
											direction="column"
											alignItems="center"
											justify="center"
										>
											{value}

											<Divider orientation="horizontal" size={1} />

											<Typography variant="caption" align="center" className={classes.libName}>
												{getInboxChannelStatusPlatformName({
													whatsappLibraryClient: instance?.inbox_channel_extra_data?.libraryClientType,
													inboxChannelType: instance?.inbox_channel_type,
													wabaBrokerType: instance?.broker_extra_data?.broker_type
												})}
											</Typography>
										</Grid>
									) : value
								}
							</TableCell>
						)
					})}
			<TableCell
				align="center"
			>
				<Tooltip
					title={`Excluir instância ${instance.id}`}
				>
					<IconButton
						disabled={!HardCodedUtil.canDeleteAccountAndInstanceOnCascade()}
						onClick={(event) => {
							event.preventDefault()
							onDelete(instance.id)
						}}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Tooltip>
				<Tooltip
					title={`Ir para detalhes da instância ${instance.id}`}
				>
					<Link
						target="_blank"
						rel="noopener noreferrer"
						to={`/inbox/instance/${instance.id}`}
					>
						<IconButton
							className={classes.launchIcon}
						>
							<ArrowIcon fontSize="small" />
						</IconButton>
					</Link>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}

export default InstanceListItem
