import { InfoDialog, Notification } from "@/components"
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"

import {
	ClearTwoTone as ClearIcon
} from "@material-ui/icons"

import React, { useState } from "react"
import { InboxAdminApi } from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { QueueColumns, QueueType } from "@/pages/Inbox/protocols/ChannelProtocol"

const TableColumns: QueueColumns[] = [
	{
		name: "name",
		label: "Fila"
	},
	{
		name: "description",
		label: "Descrição"
	}
]

const queues: QueueType[] = [
	{
		name: "DeliverMessage",
		description: "Fila do inbox"
	},
	{
		name: "SendMessageBatch",
		description: "Fila do WA-API"
	},
	{
		name: "IntegrationMessage",
		description: "Integrações"
	},
	{
		name: "MessageBlastMessage",
		description: "Envio em massa"
	},
	{
		name: "WebhookChatBot",
		description: "Chatbots de Webhook"
	},
	{
		name: "ChatBotMessage",
		description: "Chatbots"
	}
]

interface ClearQueuesDialogProps{
	showClearQueuesDialog?: boolean
	setShowClearQueuesDialog?: (value: boolean) => void
	inboxChannelIntegrationId: string
}

const ClearQueuesDialog = (props: ClearQueuesDialogProps) => {
	const { showClearQueuesDialog, setShowClearQueuesDialog, inboxChannelIntegrationId } = props
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)

	const { triggerValidation } = useValidation()

	const handleClearQueue = async (queue: QueueType) => {
		setActionDialogLoading(true)
		try {
			await InboxAdminApi.get(`/channels/${inboxChannelIntegrationId}/queues/${queue.name}/clear`)

			Notification.success({ message: "Fila limpa com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error as ErrorType)
		}
		setActionDialogLoading(false)
	}

	return <InfoDialog
		title="Limpar filas"
		onClose={() => setShowClearQueuesDialog && setShowClearQueuesDialog(false)}
		openDialog={showClearQueuesDialog}
		fullWidth
	>
		<TableContainer>
			<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
						{TableColumns
							.map((column, index) => (
								<TableCell
									key={index}
								>
									{
										column.label
									}
								</TableCell>
							))}
						<TableCell align="center">
							Ações
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						queues.map((queue, index) => {
							return (
								<TableRow
									hover
									tabIndex={-1}
									key={index}
								>
									{TableColumns
										.map((column, index) => {
											const value = queue[column.name] as string

											return (
												<TableCell
													key={index}
												>
													{value}
												</TableCell>
											)
										}
										)
									}

									<TableCell
										align="center"
										style={{ display: "flex", justifyContent: "center" }}
									>
										{
											actionDialogLoading ? <CircularProgress/>
												: <IconButton
													key={queues.length + index}
													onClick={(event) => {
														event.preventDefault()
														handleClearQueue(queue)
													}}
												>
													<ClearIcon fontSize="small" />
												</IconButton>
										}

									</TableCell>

								</TableRow>
							)
						})}
				</TableBody>
			</Table>
		</TableContainer>
	</InfoDialog>
}

export default ClearQueuesDialog
