import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	tableCell: {
		display: "flex",
		justifyContent: "flex-end"
	},
	launchIcon: {
		padding: "5px"
	},
	tabContent: {
		paddingTop: "30px"
	},
	childrenTable: {
		paddingLeft: "20px"
	}
})

export default useStyles
