import React, { useState } from "react"
import { match } from "react-router"
import { Link as LinkRouter } from "react-router-dom"
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	Breadcrumbs,
	TableContainer,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableCellProps,
	FormControl,
	Select,
	MenuItem
} from "@material-ui/core"

import { AccountAdminApi, InboxAdminApi } from "@/services/Api"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import { ActionDialog, Divider, Loading, Portlet, Notification, PhoneInput, CustomTextField } from "@/components"

import { UserInInstanceCard } from "@/pages/Account/Account/Details/components/UserInInstanceCard"

import { formatCnpj, formatPhoneNumber } from "@/utils/mask"

import { AccountFullData, AccountType, UserData, UserDataKeys } from "@/pages/Account/protocols/AccountProtocol"

import CardSkeleton from "@/skeletons/CardSkeleton"

import useStyles from "@/pages/Account/Account/Details/styles"

import { GetInstancesResponseProps } from "@/pages/Inbox/protocols/InstanceProtocol"
import { CountryData, PhoneNumberType } from "@/components/PhoneInput"
import PaymentInformationData from "@/pages/Account/Account/Details/components/PaymentInformationData"
import AppInstanceRow from "@/pages/Account/Account/Details/components/AppInstanceRow"

type AccountDetailsProps = {
	match: match & {
		params: {
			accountId: number
		}
	}
}

type InstanceColumn = {
	name: "id"
	label: string,
	style?: React.CSSProperties
	align?: TableCellProps["align"]
}

const TableColumns: InstanceColumn[] = [
	{
		name: "id",
		label: "ID"
	}
]

const AccountDetails: React.FC<AccountDetailsProps> = (props) => {
	const {
		match
	} = props

	const {
		params: { accountId }
	} = match

	const [accountData, setAccountData] = useState<AccountFullData>({} as AccountFullData)
	const [getAccountDataLoading, setGetAccountDataLoading] = useState<boolean>(true)

	const [inboxInstancesData, setInboxInstancesData] = useState<GetInstancesResponseProps>({} as GetInstancesResponseProps)
	const [getInboxInstanceLoading, setGetInboxInstanceLoading] = useState<boolean>(true)

	const [newUserData, setNewUserData] = useState<UserData>({
		key: "",
		value: ""
	})

	const [newPhoneNumberData, setNewPhoneNumberData] = useState<PhoneNumberType>({
		value: "",
		countryCode: ""
	})

	const [dialogLabel, setDialogLabel] = useState("")
	const [userDatakey, setUserDataKey] = useState<UserDataKeys>("")
	const [textfieldValue, setTextFieldValue] = useState("")

	const [openEditUserDataDialog, setOpenEditUserDataDialog] = useState<boolean>(false)
	const [openEditAccountTypeDialog, setOpenEditAccountTypeDialog] = useState<boolean>(false)
	const [accountTypes, setAccountTypes] = useState<AccountType[]>([])

	const {
		triggerValidation,
		validation
	} = useValidation()
	const classes = useStyles()

	const getInboxInstancesData = async (accountInstancesIds: number[]) => {
		setGetInboxInstanceLoading(true)
		try {
			if (accountInstancesIds.length > 0) {
				const { data } = await InboxAdminApi.get("/instances", {
					params: {
						account_instance_id: accountInstancesIds
					}
				})

				setInboxInstancesData(data)
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setGetInboxInstanceLoading(false)
	}

	const getAccountData = async () => {
		setGetAccountDataLoading(true)
		try {
			const { data } = await AccountAdminApi.get(`/account/full-data/${accountId}`)

			const accountData = data as AccountFullData

			setAccountData(accountData)

			await getInboxInstancesData(accountData.appInInstancesData.map((appInstance) => appInstance.id))
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setGetAccountDataLoading(false)
	}

	const openEditDialog = (data: string, fieldName: UserDataKeys) => {
		if (fieldName === "name") {
			setDialogLabel("Nome")
			setUserDataKey("name")
		}

		if (fieldName === "cnpj") {
			setDialogLabel("Cnpj")
			setUserDataKey("cnpj")
		}

		if (fieldName === "email") {
			setDialogLabel("Email")
			setUserDataKey("email")
		}

		if (fieldName === "phone_number") {
			setDialogLabel("Celular")
		}
		setTextFieldValue(data)

		setOpenEditUserDataDialog(true)
	}

	const handleClearNewUserData = () => {
		setNewUserData({
			key: "",
			value: ""
		})

		setNewPhoneNumberData({
			value: "",
			countryCode: ""
		})
	}

	const handleCloseEditDataDialog = async () => {
		setOpenEditUserDataDialog(false)
		handleClearNewUserData()
		setTextFieldValue("")
	}

	const handleSaveNewUsersData = async () => {
		try {
			const { key, value } = newUserData
			if (newUserData.value) {
				await AccountAdminApi
					.put(`/account/details/${accountId}`, {
						[key]: value
					})

				setOpenEditUserDataDialog(false)
				Notification.success({ message: "Usuário atualizado com sucesso!" })
				getAccountData()
			} else if (newPhoneNumberData.value) {
				await AccountAdminApi
					.put(`/account/details/${accountId}`, {
						phone_number: newPhoneNumberData.value
					})

				setOpenEditUserDataDialog(false)
				Notification.success({ message: "Usuário atualizado com sucesso!" })
				getAccountData()
			} else {
				Notification.info({ message: "Nenhum dado foi alterado!" })
			}
			setTextFieldValue("")
			handleClearNewUserData()
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Erro ao tentar atualizar os dados do usuário!" })
		}

		setOpenEditUserDataDialog(false)
	}

	const handleUserDataChange = (key: UserDataKeys, value: string) => {
		setTextFieldValue(value)
		setNewUserData({
			key,
			value
		})
	}

	const handlePhoneNumberData = (phoneNumber: PhoneNumberType) => {
		const { value, countryCode } = phoneNumber

		setNewPhoneNumberData({
			value,
			countryCode
		})
	}

	const handlePhoneNumberChange = (value: string, country?: CountryData) => {
		handlePhoneNumberData({
			value,
			...(country && { countryCode: country.countryCode })
		})
	}

	const handleOpenEditAccountTypeDialog = () => {
		setOpenEditAccountTypeDialog(true)
	}

	const handleCloseEditAccountTypeDialog = () => {
		setOpenEditAccountTypeDialog(false)
	}

	const handleSaveAccountType = async () => {
		try {
			await AccountAdminApi
				.put(`/account/${accountId}/extra-data`, {
					account_type: accountData.extra_data?.account_type
				})
			Notification.success({ message: "Usuário atualizado com sucesso!" })
			handleCloseEditAccountTypeDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Erro ao tentar atualizar os dados do usuário!" })
		}
	}

	const getAndUpdateAccountTypes = async () => {
		try {
			const response = await AccountAdminApi.get("/accounts/types")
			setAccountTypes(response.data)
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Erro ao buscar os tipos de conta!" })
		}
	}

	useDidMount(() => {
		getAccountData()
		getAndUpdateAccountTypes()
	})

	return (
		<>
			<Grid spacing={3} container justify="flex-start">
				<Grid item xs={12}>
					<Breadcrumbs aria-label="breadcrumb">
						<LinkRouter color="textPrimary" to="/account" className={classes.instanceLink}>
							Accounts
						</LinkRouter>

						<Typography
							color="textPrimary"
						>
							{accountId}
						</Typography>

						<Typography
							color="textPrimary"
						>
							Detalhes
						</Typography>
					</Breadcrumbs>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Typography
								variant="h2"
								className={classes.title}
							>
								DADOS DA CONTA
							</Typography>

							<Divider size={2} orientation="horizontal" />

							<Loading
								customLoadingElement={
									<CardSkeleton
										cardHeight={200}
									/>}
								loading={getAccountDataLoading}
							>
								<Portlet
									style={{
										padding: "8px 16px",
										minHeight: "220px"
									}}
									elevation={1}
								>
									<Grid spacing={1} container justify="center" alignItems="center">
										<Grid item xs>
											<List>
												<ListItem
													className={classes.listItem}
												>
													<ListItemText
														primary={<Typography className={classes.listPrimaryText} variant="caption">CNPJ</Typography>}
														secondary={
															<UserInInstanceCard
																name={formatCnpj(accountData?.cnpj || "")}
																onClickEditButton={() => openEditDialog(accountData?.cnpj || "", "cnpj")}
															/>
														} />
												</ListItem>
												<ListItem
													className={classes.listItem}
												>
													<ListItemText
														primary={<Typography className={classes.listPrimaryText} variant="caption">RAZÃO SOCIAL</Typography>}
														secondary={
															<UserInInstanceCard
																name={accountData?.name}
																onClickEditButton={() => openEditDialog(accountData?.name, "name")}
															/>
														} />
												</ListItem>

												<ListItem
													className={classes.listItem}
												>
													<ListItemText
														primary={<Typography className={classes.listPrimaryText} variant="caption">EMAIL</Typography>}
														secondary={
															<UserInInstanceCard
																name={accountData?.email}
																onClickEditButton={() => openEditDialog(accountData?.email, "email")}
															/>
														} />
												</ListItem>

												<ListItem
													className={classes.listItem}
												>
													<ListItemText
														primary={<Typography className={classes.listPrimaryText} variant="caption">CELULAR</Typography>}
														secondary={
															<UserInInstanceCard
																name={formatPhoneNumber(accountData.phone_number)}
																onClickEditButton={() => openEditDialog(accountData.phone_number, "phone_number")}
															/>
														}
													/>
												</ListItem>

												<ListItem
													className={classes.listItem}
												>
													<ListItemText
														primary={<Typography className={classes.listPrimaryText} variant="caption">TIPO DE CONTA</Typography>}
														secondary={
															<UserInInstanceCard
																name={accountData.extra_data?.account_type || "---"}
																onClickEditButton={handleOpenEditAccountTypeDialog}
															/>
														}
													/>
												</ListItem>

											</List>
										</Grid>

									</Grid>
								</Portlet>
							</Loading>

						</Grid>
						<PaymentInformationData
							getInboxInstanceLoading={getInboxInstanceLoading}
							accountData={accountData}
						/>
						<ActionDialog
							title={`Editar ${dialogLabel.toLocaleLowerCase()}`}
							onSave={() => handleSaveNewUsersData()}
							saveText="Salvar"
							onClose={handleCloseEditDataDialog}
							openDialog={openEditUserDataDialog}
							fullWidth
						>
							<Grid container>
								<Grid item xs={12}>
									{dialogLabel !== "Celular"
										? <CustomTextField
											onChange={
												({ target }) => handleUserDataChange(userDatakey, target.value)
											}
											value={textfieldValue}
											variant="outlined"
											placeholder={dialogLabel}
											fullWidth
										/>
										: <PhoneInput
											value={textfieldValue}
											specialLabel="Celular"
											country={"br"}
											preferredCountries={["br", "pt", "us"]}
											onChange={(phoneNumber: string, countryCode: CountryData) => handlePhoneNumberChange(phoneNumber, countryCode as CountryData)}
											validation={validation.phoneNumber}
										/>
									}
								</Grid>

								<Divider size={2} orientation="horizontal" />
							</Grid>
						</ActionDialog>
						<ActionDialog
							title={"Editar tipo de conta"}
							onSave={() => handleSaveAccountType()}
							saveText="Salvar"
							onClose={handleCloseEditAccountTypeDialog}
							openDialog={openEditAccountTypeDialog}
							fullWidth
						>
							<Grid container>
								<Grid item xs={12}>
									<FormControl fullWidth variant="outlined">
										<Select
											displayEmpty
											renderValue={(selected) => {
												if (selected) {
													return selected as string
												} else {
													return "Selecione o tipo"
												}
											}}
											value={accountData?.extra_data?.account_type}
											onChange={({ target }) => {
												setAccountData({
													...accountData,
													extra_data: {
														...accountData.extra_data,
														account_type: target.value as AccountType
													}
												})
											}}
										>
											<MenuItem value="">
												---
											</MenuItem>

											{accountTypes.map(type => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Divider size={2} orientation="horizontal" />
							</Grid>
						</ActionDialog>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Typography
						variant="h2"
						className={classes.title}
					>
						INSTÂNCIAS
					</Typography>

					<Divider size={3} orientation="horizontal" />

					<Portlet
						elevation={1}
					>
						<Grid container>
							<Grid item xs={12}>
								<Grid item xs>
									<TableContainer>
										<Table stickyHeader aria-label="sticky table">
											<TableHead>
												<TableRow>
													{TableColumns
														.map((column, index) => (
															<TableCell
																key={index}
															>
																{column.label}
															</TableCell>
														))}
													<TableCell align="center" className={classes.tableCell} colSpan={2}>
														Ações
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{
													inboxInstancesData.data?.map((instance, index) => {
														return (
															<AppInstanceRow
																appInstanceData={{
																	instanceId: instance.id,
																	accountId,
																	appInstanceId: instance.account_instance_id
																}}
																key={index}
															/>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Grid>
					</Portlet>
					<Divider size={2} orientation="horizontal" />
				</Grid>

			</Grid>
		</>
	)
}

export default AccountDetails
